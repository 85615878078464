import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment-timezone"
import { DisappearedLoading } from "react-loadingg";
import { ToggleButtonField } from './ToggleButton'

import client from "../../Api/HTTPClient";
import api from "../../constant/apilist";
import swal from 'sweetalert';
import "../../table.css"

import imgunverified from "../../assets/images/doc-rejected-icon.png";
import Compressor from "compressorjs";
import imgverified from "../../assets/images/doc-approved-icon.png";

class VerifiedMerchantDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            merchant_id: this.props.merchant_id,
            merchant_info: null,
            documentsDetails: null,
            directores: null,
            isOpen: false,
            imageSrc: null,
            access_level: localStorage.getItem("accessLevel"),
            inputKey: "input",
            verifyModal: false,
            verifyStatus: null,
            rejectStatusReason: "",
            verifyloading : true,
            adminUser: JSON.parse(localStorage.getItem("user")),
            docActionModal: {
                open : false,
                document_no : null,
                action_selected: 0,
                action_comment: "",
                updateColumn: "",
                add_doc : null
            },
            requestDocModal: {
                open : false,
                document_name : null,
            },
            selectedId: null,
            reqAdditionalDocModal: false,
            additional_doc: {
                name: '',
                photo_type: 1, // 1 : front only, 2 : front and back
            },
            reqVerifyModal: false,
            isVerifiedToggle: false,
            timestamp: new Date().valueOf(),
            loading: false
        }

    }

    toggleVerifiedToggle = () => {
        this.setState({ isVerifiedToggle: !this.state.isVerifiedToggle})
    }

    toggleReqVerifyModal = () => {
        this.setState({reqVerifyModal: !this.state.reqVerifyModal})
    }

    toggleRequestAdditionalDocModal = (status) => {
        this.setState({reqAdditionalDocModal : status ? status : !this.state.reqAdditionalDocModal})
    }

    componentDidMount() {
        const { documentsDetails } = this.state;
        this.getDocuments();
        this.getmerchant_info();

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        console.log(nextProps, prevState)
        // if (nextProps.merchant_info.is_verified != prevState.merchant_info.is_verified) {
        //     return {
        //         merchant_info: nextProps.merchant_info,

        //     }
        // }
    }

    getmerchant_info() {
        const { merchant_id } = this.state;
        const postData = { merchant_id: merchant_id }

        client.post(api.merchant_info, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    
                    this.setState({merchant_info : response.result})

                } else {
                    // setTimeout(() => {
                    // toast.error(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                // toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        })
    }
    getDocuments() {
        const { merchant_id, documentsDetails } = this.state;
        const postData = { merchant_id: merchant_id }

        client.post(api.get_merchant_documents, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    // console.log(response);

                    /*  if(response.result.data.length != 0){
                         let directores = JSON.parse(response.result.data.directores);
                         this.setState({
                             documentsDetails: response.result.data,
                             directores: directores
                         })
                     }else{
                         this.setState({
                             documentsDetails: response.result.data,
                         
                         })
                     } */

                    if (response.result) {
                        let directores = []
                        try {
                            directores = JSON.parse(response.result.data.directores);
                        } catch {

                        }
                        console.log("this run");
                        this.setState({
                            documentsDetails: response.result.data,
                            directores: directores,
                            isVerifiedToggle: response.result.data.req_doc_verify,
                            timestamp: new Date().valueOf(),
                            loading:false
                        })
                    }

                } else {
                    // setTimeout(() => {
                    toast.error(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        })
    }

    refreshPage=()=> {
        this.getDocuments();
        this.getmerchant_info();
    }

    imageClicked = (src) => {
        //console.log("clicked");
        this.setState({ isOpen: true, imageSrc: src })
    }

    documentChangeHandler = async (event, doc_no, add_doc = null) => {
        if (event.target.files.length === 0)
            return;
        //Image upload validation
        var mimeType = event.target.files[0].type;
        this.setState({loading: true})
        

        if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
            
            toast.error('Wrong file format. Allowed jpg, jpeg, png & pdf')
            console.log(event.target.files[0])
            event.target.value=''
            // this.refreshPage();
            
            return ;
        }

        let thisRef = this;

        new Compressor(event.target.files[0], {
            quality: 0.6,
            maxHeight: 1920,
            maxWidth: 1920,
            convertSize: 500000,
            success(result) {
                        // Image upload
        var reader = new FileReader();
        console.log(reader)
        reader.readAsDataURL(result);
        reader.onload = (_event) => {
            let imageData;
            thisRef.setState({ url: reader.result });
            imageData = reader.result;
            imageData = imageData.split(",")[1];

            let image_data = { mime: mimeType, data: imageData }
            let current = thisRef;

            const postData = { merchant_id: thisRef.state.merchant_id, image_data: image_data, document_no: doc_no, is_admin: true, add_doc }
            client.post(api.upload_merchant_document, postData, (error, response) => {
                if (!error) {
                    if (!response.error) {
                        toast.success(response.message);
                        current.refreshPage();
                        current.setState({ inputKey: Math.random().toString(36) });
                    } else {
                        setTimeout(() => {
                            toast.error(response.message);
                        }, 200);
                    }
                } else {
                    setTimeout(() => {
                        toast.error("Internal error occured. Please contact support");
                    }, 200);


                }
            })
        }
            },
            error(err) {
                console.log(err.message);
            },
        });
    }


    informationChangeHandler = (event) => {
        console.log(event.target.type);
        const { name, value } = event.target;
        if (event.target.type == "file") {
            var mimeType = event.target.files[0].type;

            if (mimeType.match(/(jpeg|jpg|png|pdf)$/) == null) {
                return;
            }
            // Image upload
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (_event) => {
                let imageData;
                this.setState({ url: reader.result });
                imageData = reader.result;
                imageData = imageData.split(",")[1];

                let image_data = { mime: mimeType, data: imageData }
                console.log(image_data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        documentsDetails: {
                            ...prevState.documentsDetails,
                            company_reg_certification: image_data
                        }
                    }
                })
            }
        } else {
            this.setState(prevState => {
                return {
                    ...prevState,
                    documentsDetails: {
                        ...prevState.documentsDetails,
                        [name]: value
                    }
                }
            });
        }
    }

    render_date = (add_date) => {

        if (typeof (add_date) === "undefined") {
            return;
        }

        const convert_date = new Date(add_date);
        const added_date = moment.tz(convert_date, "Australia/Sydney").format("DD/MM/YYYY HH:mm");
        return added_date;
    }

    informationSubmitHandler = (event) => {
        event.preventDefault();
        const { merchant_id, documentsDetails, directores } = this.state;
        const postData = { merchant_id: merchant_id, ...this.state.documentsDetails }
        let current = this;

        client.post(api.update_merchant_addInfo, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message);
                    current.refreshPage();
                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    acnChangeHandler = (event) => {
        const { name, value } = event.target;
        if (value == "yes") {
            this.setState(prevState => {
                return {
                    ...prevState,
                    documentsDetails: {
                        ...prevState.documentsDetails,
                        [name]: true

                    }
                }
            })
        } else if (value == "no") {
            this.setState(prevState => {
                return {
                    ...prevState,
                    documentsDetails: {
                        ...prevState.documentsDetails,
                        [name]: false

                    }
                }
            })

        }
    }

    directorsChangeHandler = (event, index) => {
        const { name, value } = event.target;
        let updatedDirectores = [...this.state.directores];
        let updatedDirector = { ...this.state.directores[index] };
        updatedDirector = {
            ...updatedDirector,
            [name]: value
        }
        updatedDirectores[index] = updatedDirector;
        const jsonData = JSON.stringify(updatedDirectores)
        this.setState(prevState => {
            return {
                ...prevState,
                documentsDetails: {
                    ...prevState.documentsDetails,
                    directores: jsonData
                },
                directores: updatedDirectores
            }
        })
    }
    

    requestDocumentAction = async (document_name) => {

        this.setState({requestDocModal : {
            open : true,
            document_name : document_name,
        }})
        
    }

    requestDocument = async (document_name) => {

        let { merchant_id } = this.state;
        localStorage.setItem('isChange',true);
        const postData = { merchant_id: merchant_id, document_name : document_name, user_name : this.state.adminUser.full_name }
        let current = this;

        client.post(api.request_document, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message);
                    current.setState({requestDocModal : {
                        open : false,
                        document_name : "",
                    }})
                    current.refreshPage();

                } else {
                    setTimeout(() => {
                        toast.error(response.message);
                    }, 200);
                }
            } else {
                setTimeout(() => {
                    toast.error("Internal error occured. Please contact support");
                }, 200);
            }
        })
    }

    changeDocumentVerified = async (document_name, add_doc=null) => {

        this.setState({docActionModal : {
            open : true,
            document_no : document_name,
            action_selected: 0,
            action_comment: "",
            add_doc
            // updateColumn : status_column
        }})

        // let status = await swal({
        // 	title: "Are you sure?",
        // 	text: "Change status to Verified",
        // 	icon: "warning",
        // 	buttons: {
        // 		cancel: true,
        // 		confirm: {
        // 			text: "Yes, Please!",
        // 			closeModal: false,
        // 		},
        // 		confirm: {
        // 			text: "Yes, Please!",
        // 			closeModal: false,
        // 		},
        // 	},
        // 	dangerMode: true,
        // });
        
        // swal({
        //     title: "",
        //     text: "Kindly approve or reject document",
        //     buttons: {
        //         ABC: {
        //             text: "Cancel",
        //             value: "Cancel",
        //             className: "btn btn-default",
        //             closeModal: true,

        //         },
        //         Reject: {
        //             text: "Reject",
        //             value: "Reject",
        //             className: "btn btn-danger",
        //         },
        //         Verify: {
        //             text: "Verify",
        //             value: "Verify",
        //             className: "btn btn-success",
        //         },
        //     },
        // })
        //     .then((value) => {
        //         switch (value) {
           
        //             case "Reject":
        //                 swal("Enter Reject Reaso", {
        //                     content: "input",
        //                 })
        //                     .then((value) => {
        //                         // swal(`You typed: ${value}`);
        //                     });
        //                 // swal("Reject");
        //                 break;
           
        //             case "Verify":
        //                 swal("Verify");
        //                 break;
           
        //         }
        //     });

        // if (status) {

        // const postData = { merchant_id: merchant_id, update: {} }

        // client.post(api.verify_merchant_document, postData, (error, response) => {
        //     if (!error) {
        //         if (!response.error) {
        //             toast.success(response.message);
        //             this.refreshPage();
        //         } else {
        //             // setTimeout(() => {
        //             toast.error(response.message);
        //             // }, 200);
        //         }
        //     } else {
        //         // setTimeout(() => {
        //         toast.error("Internal error occured. Please contact support");
        //         // }, 200);
        //     }
        // })
        // }
        
    }

    renderAllTable = () => {
        const { documentsDetails, access_level, inputKey } = this.state;
        let is_verified = this.state.merchant_info?.is_verified;
        let a = 2;
        if(is_verified!=1 || documentsDetails.registration_cert!=null){
            ++a;
        }
        if(is_verified!=1 || documentsDetails.utility_bill!=null){
            ++a;
        }
        if(is_verified!=1 || documentsDetails.store_picture!=null){
            ++a;
        }

        return (
            <Fragment>
               { this.state.loading ? 
               <div className="loader-box" style={{ height: "100px" }}>
                    <div className="loader" style={{ display: "flex", alignItems: "center" }}>
                        <DisappearedLoading size="medium" color="#514F4E" />
                    </div>
                </div>
               :<div className="table-responsive">
                    <table className="table table-border-horizontal table-striped" >
                        <thead>
                            <tr>
                                <th style={{ width: "" }}>#</th>
                                <th style={{ width: "" }}></th>
                                {access_level != 1 && <td style={{ width: "" }}>Upload</td>}
                                <th style={{ width: ""}}>Previous Uploads</th>
                                <th style={{ width: "" }}>Attachments</th>
                                <th style={{ width: "25%" }}>Approve/Reject Documents</th>
                                <th style={{ width: ""}}><span>Updated by: email/date/time</span></th>
                                <th style={{ width: "" }}>Forced Documents</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th style={{ width: "" }}>1</th>
                                <td style={{ width: "" }}>Photo Id(Passport/Driving License/Medicare) front</td>
                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name="identity_front" onChange={(event) => this.documentChangeHandler(event, 8)} /></td>}
                                <td>
                                    {documentsDetails.if_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.if_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.if_previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td>
                                <td style={{ width: "" }}>{documentsDetails.identity_front != null ? documentsDetails.identity_front.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.identity_front}`)}?time=${this.state.timestamp}`}>{documentsDetails.identity_front}</a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.identity_front}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.identity_front}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>} </td>
                                {documentsDetails.if_uploaded ?
                                    <td>
                                        {documentsDetails.if_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("identity_front")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.if_status == 1 ? imgverified : imgunverified}
                                                />
                                                {documentsDetails.if_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.if_status_comment}</span>
                                                }
                                            </>
                                        }
                                    </td>
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.if_updated != null && documentsDetails?.if_updated_by } {documentsDetails?.if_updated != null && moment.tz(new Date(documentsDetails?.if_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")} </td>
                                <td></td>
                            </tr>
                            <tr>
                                <th style={{ width: "" }}></th>
                                <td style={{ width: "" }}>Photo Id(Passport/Driving License/Medicare) back</td>
                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name="identity_back" onChange={(event) => this.documentChangeHandler(event, 9)} /></td>}
                                <td>
                                    {documentsDetails.ib_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.ib_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.ib_previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td>
                                <td style={{ width: "" }}>{documentsDetails.identity_back != null ? documentsDetails.identity_back.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.identity_back}`)}?time=${this.state.timestamp}`}>{documentsDetails.identity_back}</a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.identity_back}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.identity_back}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>} </td>
                                {documentsDetails.ib_uploaded ?
                                    <td>
                                        {documentsDetails.ib_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("identity_back")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.ib_status == 1 ? imgverified : imgunverified}
                                                />
                                                {documentsDetails.ib_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.ib_status_comment}</span>
                                                }
                                            </>
                                        }
                                    </td>
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.ib_updated && documentsDetails?.ib_updated_by} {documentsDetails?.ib_updated && moment.tz(new Date(documentsDetails?.ib_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")} </td>
                                <td></td>
                            </tr>

                            <tr >
                                <th scope="col" style={{ width: "" }}></th>
                                <td scope="col" style={{ width: "" }}>Date Of birth</td>
                                {/* <th scope="col" style={{ width: "12%" }}>Points</th> */}
                                {access_level != 1 && <td scope="col" style={{ width: "" }}>{documentsDetails.dob} </td>}
                                {/* <td scope="col" style={{ width: "30%" }}>Attachments </td> */}
                                <td></td>
                                <td scope="col" style={{ width: "" }}></td>
                                <td scope="col" style={{ width: "" }}></td>
                                <td></td>
                                <td scope="col" style={{ width: "" }}></td>
                            </tr>
                            
                            <tr>
                                <th style={{ width: "" }}>2</th>
                                <td style={{ width: "" }}>Photo </td>
                                {/* <td style={{ width: "12%" }}>50</td> */}
                                {access_level != 1 && <td style={{ width: "" }}>
                                    <input type="file" name="person_picture" key={inputKey}  onChange={(event) => this.documentChangeHandler(event, "person_picture")} /></td>}
                                <td>
                                    {documentsDetails.pp_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.pp_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.pp_previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td>
                                <td style={{ width: "" }}>{documentsDetails.person_picture != null ? documentsDetails.person_picture.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.person_picture}`)}?time=${this.state.timestamp}`}>{documentsDetails.person_picture}</a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.person_picture}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.person_picture}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>} </td>
                                {documentsDetails.pp_uploaded ?
                                    <td>
                                        {documentsDetails.pp_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("person_picture")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.pp_status == 1 ? imgverified : imgunverified}
                                                />
                                                {documentsDetails.pp_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.pp_status_comment}</span>
                                                }
                                            </>
                                        }
                                    </td>
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.pp_updated && documentsDetails?.pp_updated_by } {documentsDetails?.pp_updated && moment.tz(new Date(documentsDetails?.pp_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")} </td>
                                <td></td>
                            </tr>

                            {!(documentsDetails.registration_cert == null && documentsDetails.rc_previous == null && this.state.merchant_info?.is_verified == 1) && <tr>
                                <th scope="row" style={{ width: "" }}>{this.state.merchant_info.is_verified != 1 ? '3' : a}</th>
                                <td style={{ width: "" }}>Registration Certificate(Registration/Business Proof)</td>
                                {/* <td style={{ width: "12%" }}>40</td> */}
                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name="registration_cert" onChange={(event) => this.documentChangeHandler(event, "registration_cert")} /></td>}
                                <td>
                                    {documentsDetails.rc_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.rc_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.rc_previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td>
                                <td style={{ width: "" }}>{documentsDetails.registration_cert != null ? documentsDetails.registration_cert.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.registration_cert}`)}?time=${this.state.timestamp}`}>{documentsDetails.registration_cert}</a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.registration_cert}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.registration_cert}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>}</td>
                                {documentsDetails.rc_uploaded ?
                                    <td>
                                        {documentsDetails.rc_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("registration_cert")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.rc_status == 1 ? imgverified : imgunverified}
                                                />
                                            
                                                {documentsDetails.rc_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.rc_status_comment}</span>
                                                }
                                            </>

                                        }
                                    </td>
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.rc_updated && documentsDetails?.rc_updated_by } {documentsDetails?.rc_updated && moment.tz(new Date(documentsDetails?.rc_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")}</td>
                                <td>
                                    {documentsDetails?.required_documents?.includes('registration_certificate') ?
                                        <>
                                            {!documentsDetails.rc_uploaded &&
                                                < span className="text-danger" style={{ marginLeft: 5 }}>Requested</span>
                                            }
                                        </>
                                        :
                                        <a href="javascript:void(0);" className="text-warning" onClick={() => this.requestDocumentAction("registration_certificate")}>
                                            <span style={{ marginLeft: 5 }}>Request</span>
                                        </a>
                                    }
                                </td>

                            </tr>}

                            {!(documentsDetails.utility_bill == null && documentsDetails.ub_previous == null && this.state.merchant_info?.is_verified == 1) &&<tr>
                                <th scope="row" style={{ width: "" }}>{this.state.merchant_info.is_verified != 1 ? '4' : a}</th>
                                <td style={{ width: "" }}>Utility Bills(Electricity/ Telecom/  Gas/ Water) </td>
                                {/* <td style={{ width: "12%" }}>40</td> */}
                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name="utility_bill"  onChange={(event) => this.documentChangeHandler(event, "utility_bill")} /></td>}
                                <td>
                                    {documentsDetails.ub_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.ub_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.ub_previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td>
                                <td style={{ width: "" }}>{documentsDetails.utility_bill != null ? documentsDetails.utility_bill.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.utility_bill}`)}?time=${this.state.timestamp}`}>{documentsDetails.utility_bill}</a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.utility_bill}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.utility_bill}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>} </td>
                                {documentsDetails.ub_uploaded ?
                                    <td>
                                        {documentsDetails.ub_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("utility_bill")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.ub_status == 1 ? imgverified : imgunverified}
                                                />
                                            
                                                {documentsDetails.ub_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.ub_status_comment}</span>
                                                }
                                            </>
                                        }
                                    </td>
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.ub_updated &&  documentsDetails?.ub_updated_by } {documentsDetails?.ub_updated && moment.tz(new Date(documentsDetails?.ub_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")}</td>
                                <td>
                                    {documentsDetails?.required_documents?.includes('utility_bill') ?
                                    
                                        <>
                                            {!documentsDetails.ub_uploaded &&
                                                < span className="text-danger" style={{ marginLeft: 5 }}>Requested</span>
                                            }
                                        </>
                                        :
                                        <a href="javascript:void(0);" className="text-warning" onClick={() => this.requestDocumentAction("utility_bill")}>
                                            <span style={{ marginLeft: 5 }}>Request</span>
                                        </a>
                                    }
                                </td>
                            </tr>}
                            {!(documentsDetails.store_picture == null && documentsDetails.sp_previous == null && this.state.merchant_info?.is_verified == 1) && <tr>
                                <th scope="row" style={{ width: "" }}>{this.state.merchant_info.is_verified != 1 ? '5' : a}</th>
                                <td style={{ width: "" }}>Store Picture </td>
                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name="store_picture" onChange={(event) => this.documentChangeHandler(event, "store_picture")} /></td>}
                                <td>
                                    {documentsDetails.sp_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.sp_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.sp_previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td>
                                <td style={{ width: "" }}>{documentsDetails.store_picture != null ? documentsDetails.store_picture.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.store_picture}`)}?time=${this.state.timestamp}`}> {documentsDetails.store_picture} </a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.store_picture}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.store_picture}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>} </td>
                                {documentsDetails.sp_uploaded ?
                                    <td>
                                        {documentsDetails.sp_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("store_picture")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.sp_status == 1 ? imgverified : imgunverified}
                                                />

                                                {documentsDetails.sp_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.sp_status_comment}</span>
                                                }
                                            </>
                                        }
                                    </td>
                                    
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.sp_updated && documentsDetails?.sp_updated_by } {documentsDetails?.sp_updated && moment.tz(new Date(documentsDetails?.sp_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")}</td>
                                <td>
                                    {documentsDetails?.required_documents?.includes('store_picture') ?
                                        <>
                                            {!documentsDetails.sp_uploaded &&
                                                < span className="text-danger" style={{ marginLeft: 5 }}>Requested</span>
                                            }
                                        </>
                                        :
                                        <a href="javascript:void(0);" className="text-warning" onClick={() => this.requestDocumentAction("store_picture")}>
                                            <span style={{ marginLeft: 5 }}>Request</span>
                                        </a>
                                    }
                                    
                                </td>
                            </tr>}

                            {
                                (documentsDetails.additional_documents && documentsDetails.additional_documents.length > 0) &&
                                documentsDetails.additional_documents.map(item => {
                                    return (<>
                                        {/* front */}
                                        
                                        <tr>
                                            <th scope="row" style={{ width: "" }}>{++a}</th>
                                            <td style={{ width: "" }}>{item.name} {item.back.required && ' - Front'}</td>
                                            {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name={item.name.replace(/\s/g,'') + "_front"} onChange={(event) => this.documentChangeHandler(event, item.name.replace(/\s/g,'') + "_front", {name : item.name, photo_type : 1})} /></td>}
                                            <td>
                                                {item?.front?.previous  ?  
                                                    <img src={`${client.url(`images/merchant-documents/${item?.front?.previous}`)}?time=${this.state.timestamp}`}
                                                    onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${item?.front?.previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                                : <p>No attachments</p>}
                                            </td>
                                            <td style={{ width: "" }}>{item?.front?.file_name && item?.front?.file_name ? item?.front?.file_name.includes(".pdf") ?
                                                <a href={`${client.url(`images/merchant-documents/${item?.front?.file_name}`)}?time=${this.state.timestamp}`}>{item?.front?.file_name} </a> :
                                                <img src={`${client.url(`images/merchant-documents/${item.front.file_name}`)}?time=${this.state.timestamp}`}
                                                    onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${item.front.file_name}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                                : <p>No attachments!</p>}</td>
                                            {item?.front?.uploaded ?
                                                <td>
                                                    {item.front.status == 0 ?
                                                        <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified(item.name, {name : item.name, photo_type : 1})}>
                                                            <i className="icon-info-alt text-warning"></i>
                                                            <span style={{ marginLeft: 5 }}>Under Review</span>
                                                        </a>
                                                        :
                                                        <>
                                                            <img
                                                                // onClick={() => this.changeDocumentVerified("identity_front")}
                                                                className="img-fluid"
                                                                style={{
                                                                    width: "15px",
                                                                    cursor: "pointer",
                                                                    marginRight: "4px",
                                                                }}
                                                                src={item.front.status == 1 ? imgverified : imgunverified}
                                                            />

                                                            {item.front.status == 1 ?
                                                                <span className="text-success">Approved</span> :
                                                                <span className="text-danger">Rejected : {item.front.status_comment}</span>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                    
                                                :
                                                <td>NA</td>
                                            }
                                            <td>{item.front?.updated && item.front?.updated_by } {item.front?.updated && moment.tz(new Date(item.front?.updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")}</td>
                                            <td>
                                                {!item.front.uploaded &&
                                                    < span className="text-danger" style={{ marginLeft: 5}}>Requested</span>
                                                }

                                            </td>
                                        </tr>
                                        
                                        {/* back */}
                                        {item.back.required &&
                                            <tr>
                                                <th scope="row" style={{ width: "" }}></th>
                                                <td style={{ width: "" }}>{item.name} - Back</td>
                                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name={item.name.replace(/\s/g,'') + "_back"} onChange={(event) => this.documentChangeHandler(event, item.name.replace(/\s/g,'') + "_back", {name : item.name, photo_type : 2})} /></td>}
                                                <td>
                                                    {item?.back?.previous ?  
                                                        <img src={`${client.url(`images/merchant-documents/${item.back.previous}`)}?time=${this.state.timestamp}`}
                                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${item.back.previous}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                                    : <p>No attachments</p>}
                                                </td>
                                                <td style={{ width: "" }}>{item.back.file_name && item?.back?.file_name ? item.back.file_name.includes(".pdf") ?
                                                    <a href={`${client.url(`images/merchant-documents/${item.back.file_name}`)}?time=${this.state.timestamp}`}>{item.back.file_name} </a> :
                                                    <img src={`${client.url(`images/merchant-documents/${item.back.file_name}`)}?time=${this.state.timestamp}`}
                                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${item.back.file_name}`)}?time=${this.state.timestamp}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                                    : <p>No attachments!</p>}</td>
                                                {item.back.uploaded ?
                                                    <td>
                                                        {item.back.status == 0 ?
                                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified(item.name, {name : item.name, photo_type : 2})}>
                                                                <i className="icon-info-alt text-warning"></i>
                                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                                            </a>
                                                            :
                                                            <>
                                                                <img
                                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                                    className="img-fluid"
                                                                    style={{
                                                                        width: "15px",
                                                                        cursor: "pointer",
                                                                        marginRight: "4px",
                                                                    }}
                                                                    src={item.back.status == 1 ? imgverified : imgunverified}
                                                                />

                                                                {item.back.status == 1 ?
                                                                    <span className="text-success">Approved</span> :
                                                                    <span className="text-danger">Rejected : {item.back.status_comment}</span>
                                                                }
                                                            </>
                                                        }
                                                    </td>
                                    
                                                    :
                                                    <td>NA</td>
                                                }
                                                <td>{item.back?.updated && item.back?.updated_by } {item.back?.updated && moment.tz(new Date(item.back?.updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")}</td>
                                                <td>
                                                {!item.back.uploaded &&
                                                    < span className="text-danger" style={{ marginLeft: 5 }}>Requested</span>
                                                }

                                                </td>
                                            </tr>
                                        }
                                    </>
                                    )
                                })
                                    
                            }

                            <tr>
                                <th scope="row" style={{ width: "" }}>{++a}</th>
                                <td style={{ width: "" }}>Signature </td>
                                {access_level != 1 && <td style={{ width: "" }}><input type="file" key={inputKey} name="signature"  onChange={(event) => this.documentChangeHandler(event, "signature")} /></td>}
                                <td>
                                    {documentsDetails.signature_previous != null ? 
                                        <img src={`${client.url(`images/merchant-documents/${documentsDetails.signature_previous}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.signature_previous}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments</p>}
                                </td> 
                                <td style={{ width: "" }}>{documentsDetails.signature != null ? documentsDetails.signature.includes(".pdf") ?
                                    <a href={`${client.url(`images/merchant-documents/${documentsDetails.signature}`)}?time=${this.state.timestamp}`}>{documentsDetails.signature} </a> :
                                    <img src={`${client.url(`images/merchant-documents/${documentsDetails.signature}`)}?time=${this.state.timestamp}`}
                                        onClick={() => this.imageClicked(`${client.url(`images/merchant-documents/${documentsDetails.signature}`)}`)} style={{ width: "25px", height: "25px", borderRadius: "50%" }}></img>
                                    : <p>No attachments!</p>}</td>
                                {documentsDetails.signature_uploaded ?
                                    <td>
                                        {documentsDetails.signature_status == 0 ?
                                            <a href="javascript:void(0);" className="text-warning" onClick={() => this.changeDocumentVerified("signature")}>
                                                <i className="icon-info-alt text-warning"></i>
                                                <span style={{ marginLeft: 5 }}>Under Review</span>
                                            </a>
                                            :
                                            <>
                                                <img
                                                    // onClick={() => this.changeDocumentVerified("identity_front")}
                                                    className="img-fluid"
                                                    style={{
                                                        width: "15px",
                                                        cursor: "pointer",
                                                        marginRight: "4px",
                                                    }}
                                                    src={documentsDetails.signature_status == 1 ? imgverified : imgunverified}
                                                />

                                                {documentsDetails.signature_status == 1 ?
                                                    <span className="text-success">Approved</span> :
                                                    <span className="text-danger">Rejected : {documentsDetails.signature_status_comment}</span>
                                                }
                                            </>
                                        }
                                    </td>
                                    
                                    :
                                    <td>NA</td>
                                }
                                <td>{documentsDetails?.signature_updated && documentsDetails?.email } {documentsDetails?.signature_updated && moment.tz(new Date(documentsDetails?.signature_updated), "Australia/Sydney").format("DD/MM/YYYY,HH:mm")}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                {/* <div className="table-responsive">
                    <table className="table table-border-horizontal table-striped">
                        <thead>
                            <tr >
                                <th scope="col" style={{ width: "6%" }}>#</th>
                                <th scope="col" style={{ width: "22%" }}>Total Points</th>
                                <th scope="col" >{documentsDetails.total_points}</th>
                            </tr>
                        </thead>
                    </table>
                </div> */}
            </Fragment>
        );
    }

    onVerifyStatusClick = (type) => {
        this.setState({ verifyModal: true, verifyStatus: type })
    }

    toggleVerifyModal = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                verifyModal: !prevState.verifyModal
            }
        })
    }

    onVerifydocuments = (event) => {



        event.preventDefault();
        let { merchant_id, merchant_info, verifyStatus, rejectStatusReason } = this.state;

        this.setState({ verifyloading: true })
        /* if (merchant_info.is_verified == 1) {
            swal("Aleady verified");
            return false;
        } else { */
        const postData = {
            merchant_id: merchant_id, admin_id: this.state.adminUser._id,
            verification_status: verifyStatus == "reject" ? 3 : 1, rejection_reason: rejectStatusReason, admin: this.state.adminUser.full_name
        }
        client.post(api.verify_merchant, postData, (error, response) => {

            if (!error) {
                if (!response.error) {
                    this.setState({ verifyModal: false, timestamp: new Date().valueOf() })
                    verifyStatus == "reject" ? swal("Rejected") : swal("Verified Successfully");
                    this.props.getMerchantWalletInfo(this.state.merchant_id)
                    this.getDocuments();
                    this.getmerchant_info();
                } else {
                    // setTimeout(() => {
                    swal(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                swal("Internal error occured. Please contact support");
                console.log(error);

                // }, 200);
            }
            this.setState({ verifyloading: false, timestamp: new Date().valueOf() })

        });
        //}
    }

    renderVerifyModal = () => {
        const { verifyStatus } = this.state;

        const style = {
            outline: "none",
            border: "1px solid #ebecf1",
            borderRadius: "4px",
            ':focus': {
                outlineColor: "#7C4DFF"
            }
            , padding: "3px 10px",
            fontSize: "1rem",

        }

        return (
            <Modal isOpen={this.state.verifyModal} toggle={this.toggleVerifyModal} className="modal-body" centered={true}>
                <form className="theme-form" noValidate="" onSubmit={this.onVerifydocuments}>
                    <ModalHeader toggle={this.toggleVerifyModal}>Verify Documents</ModalHeader>
                    <ModalBody>
                        {verifyStatus == "reject" ?
                            <Fragment>
                                <h6 className="mb-3">Kindly enter a note for user</h6>
                                <input type="text" size="40" style={style} value={this.state.rejectStatusReason} onChange={(event) => this.setState({ rejectStatusReason: event.target.value })}
                                    placeholder="Enter a reason for your action"
                                    required></input>
                            </Fragment> :
                            <Fragment>
                                <h6 className="mb-3">Please confirm that you have verified the following details:</h6>
                                <div>
                                    <p><input required type='checkbox' /> Verified email address</p>
                                    <p><input required type='checkbox' /> Verified authentication over the phone</p>
                                </div>
                            </Fragment>}
                    </ModalBody>
                        
                    <ModalFooter>
                        {!this.state.verifyloading ?
                        
                            <div style={{  }}>
                                {/* <DisappearedLoading size="medium" color="#514F4E" /> */}
                            <div className="loader-box pull-right" style={{ height: 'auto' }}><span className="rotate dashed" style={{ height: 20, width: 20 }}></span></div>

                            </div>
                            :

                            <>
                                <Button color="secondary" onClick={this.toggleVerifyModal}>Not sure</Button>
                                <Button color="primary" type="submit"  >Yes, please!</Button>
                            </>
                        }
                        </ModalFooter>
                </form>
            </Modal>

        );
    }

    /* verifycustomer = async () => {

        let { merchant_info } = this.state;
        if (merchant_info.is_verified == 1) {
            swal("aleady verified");
            return false;
        }
        let willDelete = await swal({
            title: "Are you sure?",
            //title:`<div class='text'><p> <input defaultChecked required type='checkbox' /> Verified email address</p> <p> <input defaultChecked required type='checkbox' /> Verified authentication over the phone</p></div>`,
           
            buttons: {
                cancel: true,
                confirm: {
                    text: "Verify!",
                    closeModal: false,
                },
            },
            dangerMode: true,
        })


        if (willDelete) {
            let { merchant_id } = this.state;

            client.post(api.verify_merchant, { merchant_id: merchant_id }, (error, response) => {
                swal.close();
                if (!error) {
                    if (!response.error) {
                        swal("Verified Successfully");
                        this.refreshPage();
                    } else {
                        // setTimeout(() => {
                        swal(response.message);
                        // }, 200);
                    }
                } else {
                    // setTimeout(() => {
                    swal("Internal error occured. Please contact support");
                    console.log(error);

                    // }, 200);
                }
            })
        }
    } */

    togglerequestDocModal = () => {
        this.setState({ requestDocModal: {
            open : false,
            document_name : "",
        } })
    }

    toggledocActionModal = () => {
        this.setState({ docActionModal: {
            open : false,
            document_no : "",
            action_selected: 0,
            action_comment: "",
            add_doc : null
            // updateColumn : "",
        } })
    }

    setaction_selected = (action_selected) => {
        let a = this.state.docActionModal;

        a.action_selected = action_selected;
        this.setState({ docActionModal: a })
    }

    docNoteHandler = (event) => {
		const comment = event.target.value;
		let a = this.state.docActionModal;

        a.action_comment = comment;
        this.setState({ docActionModal: a })
    };
    
    proceed_doc_action = () => {

        const { merchant_id, docActionModal } = this.state;
        const userId = localStorage.getItem('userId');
        localStorage.setItem('isChange',true);
        const email = this.state.adminUser.email
        const postData = {
            merchant_id: merchant_id, userId: userId, email: email, ...docActionModal
        }
        let current = this;
        client.post(api.verify_merchant_document, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message);
                    // this.refreshPage();
                    current.setState({
                        documentsDetails: response.result.documents,
                    })
                    this.toggledocActionModal();
                    // window.location.reload(true);
                } else {
                    // setTimeout(() => {
                    toast.error(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        })
        
    }

    request_doc_action = (value) => {

        const { merchant_id } = this.state;
        const userId = localStorage.getItem('userId');
        localStorage.setItem('isChange',true);
        const email = this.state.adminUser.email;
        const name = this.state.adminUser.full_name;
        let document_no = "request_doc_verify";
        const postData = {
            merchant_id: merchant_id, userId: userId, email: email, name: name, req_doc_value: value, document_no: document_no
        }
        let current = this;
        client.post(api.verify_merchant_document, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message);
                    // this.refreshPage();
                    // current.setState({
                    //     req_doc_value: response.result.documents.req_doc_verify,
                    // })
                    // this.toggledocActionModal();
                    // window.location.reload(true);
                    this.setState({isVerifiedToggle: value});
                    
                } else {
                    // setTimeout(() => {
                    toast.error(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        })
        
    }


    request_new_document = (e) => {
        e.preventDefault();
        const { additional_doc, merchant_id } = this.state;

        // console.log(data, "data")
        const postData = {
            merchant_id: merchant_id,
            document_name: additional_doc.name,
            photo_type : additional_doc.photo_type
        }
        let current = this;
        client.post(api.request_additional_document, postData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    toast.success(response.message);
                    current.refreshPage();
                    current.setState({
                        documentsDetails: response.result.documents,
                    })
                    current.toggleRequestAdditionalDocModal(false);
                } else {
                    // setTimeout(() => {
                    toast.error(response.message);
                    // }, 200);
                }
            } else {
                // setTimeout(() => {
                toast.error("Internal error occured. Please contact support");
                // }, 200);
            }
        })
        
    }


    render() {

        const { documentsDetails, reqAdditionalDocModal, merchant_info, additional_doc, access_level, docActionModal, requestDocModal } = this.state;

        let show_reject_button = false;
        if (documentsDetails) {
            let ad_doc = documentsDetails.additional_documents;
            let ad_rejected = false
            if (ad_doc && ad_doc.length > 0) {
                ad_doc.map(item => {
                    if (!item.front.uploaded || (item.front.status == 2 || item.back.status == 2)) {
                        ad_rejected = true
                    }
                })
            }

            if (ad_rejected || documentsDetails.if_status == 2 || documentsDetails.ib_status == 2 || documentsDetails.ub_status == 2 || documentsDetails.sp_status == 2 || documentsDetails.pp_status == 2 || documentsDetails.signature_status == 2 || documentsDetails.rc_status == 2 || (documentsDetails?.required_documents?.includes('registration_certificate') && !documentsDetails.rc_uploaded)
                || (documentsDetails?.required_documents?.includes('utility_bill') && !documentsDetails.ub_uploaded)
                || (documentsDetails?.required_documents?.includes('store_picture') && !documentsDetails.sp_uploaded)
            ) {
                show_reject_button = true;
            }
        }

        if (!merchant_info) {
            return null
        }

        // console.log(documentsDetails?.additional_documents, "documentsDetails.additional_documents")

        return (
            <div className="container-fluid">
                {documentsDetails != null ?
                    
                    <>
                        {
                            <Modal isOpen={reqAdditionalDocModal} toggle={() => { this.toggleRequestAdditionalDocModal(false) }} className="modal-body" centered={true}>
                                <form className="theme-form" noValidate="" onSubmit={(event) => this.request_new_document(event)}>
                                    <ModalHeader toggle={() => { this.toggleRequestAdditionalDocModal(false) }}>Enter Document Info</ModalHeader>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group col-md-12 ">
                                                    <label>Document Name</label>
                                                    <input className="form-control" required type="text" name='transf_amt' value={additional_doc.name} onChange={(event) => this.setState({ additional_doc: { ...additional_doc, name: event.target.value } })}></input>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">

                                            <div className="col">
                                                <div className="form-group col-md-12 " style={{ display: "flex" }}>
                                                    <div className="radio radio-primary" style={{ marginTop: "10px" }}>
                                                        <input checked={additional_doc.photo_type == 1} id="only_front_side" type="radio" name="document_type" value="1" onClick={(event) => {
                                                            this.setState({ additional_doc: { ...additional_doc, photo_type: 1 } })
                                                        }} />
                                                        <label className="mb-0" htmlFor="only_front_side">
                                                            Only front side
                                                        </label>
                                                    </div>
                                                    <div className="radio radio-primary" style={{ marginTop: "10px", marginLeft : 20 }}>
                                                        <input id="only_front_back" checked={additional_doc.photo_type == 2} type="radio" name="document_type" value="2" onClick={(event) => {
                                                            this.setState({ additional_doc: { ...additional_doc, photo_type: 2 } })
                                                        }} />
                                                        <label className="mb-0" htmlFor="only_front_back">
                                                            Front & Back both
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button type="button" color="default" onClick={() => { this.toggleRequestAdditionalDocModal(false) }}>Cancel</Button>
                                        <Button color="primary" type="submit"  >Send Request</Button>
                                    </ModalFooter>
                                </form>
                            </Modal>
                        }
                        
                        <Modal isOpen={docActionModal.open} toggle={this.toggledocActionModal} className="modal-body" centered={true}>
                            <ModalBody>
                                <form onSubmit={(event) => this.changeDocumentVerified(event, this.state.selectedId)} className="needs-validation">
                                    <div style={{ fontSize: "3rem", textAlign: "center" }}>
                                        <i className="icofont icofont-exclamation-tringle" style={{ color: "red" }}></i>
                                    </div>
                                    {docActionModal.action_selected == 0 ?
                                        <h3 style={{ textAlign: "center" }}>Kindly approve or reject document.</h3>
                                        :
                                        <h3 style={{ textAlign: "center" }}>Are you sure</h3>
                                    }
                                    {/* <p style={{ color: "red", textAlign: "center" }}>Note: Once blocked, Customer will not be able to sign in!.</p> */}
                                    {docActionModal.action_selected == 2 &&
                                        <div>
                                            <label className="form-label" style={{ display: "block" }}>
                                                Note:
                                            </label>
                                            <input
                                                type="text"
                                            size="40"
                                            className="form-control"
                                                onChange={this.docNoteHandler}
                                                // style={style}
                                                placeholder="Please enter comment for your action"
                                                required
                                            ></input>
                                             
                                        </div>
                                    }
                                    {docActionModal.action_selected == 0 ?
                                        <div className="d-flex justify-content-center">
                                            <Button onClick={() => this.setaction_selected(1)} color="success" style={{ marginTop: "20px", marginRight: "20px" }}>
                                                Approve
                                            </Button>
                                            <Button onClick={() => this.setaction_selected(2)} color="danger" style={{ marginTop: "20px", marginRight: "20px" }}>
                                                Reject
                                            </Button>
                                        </div>
                                        :
                                        <div className="d-flex justify-content-center">
                                            <Button onClick={() => this.toggledocActionModal()} color="default" style={{ marginTop: "20px", marginRight: "20px" }}>
                                                Cancel
                                            </Button>
                                            <Button  disabled={docActionModal.action_comment || docActionModal.action_selected==1 ?false:true}  onClick={() => this.proceed_doc_action()} color="warning" style={{ marginTop: "20px", marginRight: "20px" }}>
                                                Yes, Sure
                                            </Button>
                                        </div>
                                        
                                    }
                                </form>
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={requestDocModal.open} toggle={this.togglerequestDocModal} className="modal-body" centered={true}>
                            <ModalBody>
                                {/* <form className="needs-validation"> */}
                                    <div style={{ fontSize: "3rem", textAlign: "center" }}>
                                        <i className="icofont icofont-exclamation-tringle" style={{ color: "red" }}></i>
                                    </div>
                                        
                                    <h3 style={{ textAlign: "center" }}>Are you sure you want the merchant to upload this document for further verification</h3>
                                
                                    <>
                                        <Button onClick={() => this.togglerequestDocModal()} color="default" style={{ marginTop: "20px", marginRight: "20px" }}>
                                        No thanks
                                        </Button>
                                        <Button onClick={() => this.requestDocument(requestDocModal.document_name)} color="warning" style={{ marginTop: "20px", marginRight: "20px" }}>
                                            Yes, Sure
                                        </Button>
                                    </>
                                        
                                {/* </form> */}
                            </ModalBody>
                        </Modal>

                        <Modal isOpen={this.state.reqVerifyModal} toggle={this.toggleReqVerifyModal} className="modal-body" centered={true}>
                            <ModalBody>
                                <form className="needs-validation">
                                    <div style={{ fontSize: "3rem", textAlign: "center" }}>
                                        <i className="icofont icofont-exclamation-tringle" style={{ color: "red" }}></i>
                                    </div>
                                        
                                    <h3 style={{ textAlign: "center" }}>Are you sure you want to request document from merchant?</h3>

                                    <h5 style={{textAlign: "center"}}>By enabling this option an email will be sent to the merchant to verify documents and merchant will no longer be able to send settlement request until their document status is marked verified.</h5>
                                
                                    <div className="d-flex justify-content-center align-items-center" style={{gap: '1rem'}}>
                                        <Button onClick={() => {this.toggleReqVerifyModal(); this.setState({isVerifiedToggle: false})}} color="default" style={{}}>
                                            Not Sure
                                        </Button>
                                        <Button onClick={() => {this.request_doc_action(true); this.toggleReqVerifyModal(); }} color="warning" style={{ }}>
                                            Yes, Please
                                        </Button>
                                    </div>
                                        
                                </form>
                            </ModalBody>
                        </Modal>

                        <div className="row">
                            <div className="col-sm-12 col-xl-12">
                                {this.renderVerifyModal()}
                                {merchant_info.is_verified != 1 && <div className="d-flex justify-content-between p-3 align-items-center">
                                    <div className="d-flex align-items-center" style={{ fontSize: '1rem',fontWeight: '600'}}>
                                        Upload Documents
                                    </div>
                                    <div style={{
                                        color: "#327ebc",
                                        // marginLeft: "10px",
                                        cursor: 'pointer',
                                        // position: 'absolute',
                                        // top: '1rem',
                                        // right: '1rem',
                                        fontSize: '1rem',
                                        border: '0.1rem solid #327ebc',
                                        padding: '0.3rem',
                                    }} onClick={() => {
                                        this.toggleRequestAdditionalDocModal(true)
                                    }}>
                                        Send additional document request
                                    </div>
                                </div>}
                                <div className="d-flex align-items-center px-3" style={{ fontSize: '0.9rem',fontWeight: '600'}}>
                                            Request Document Verification
                                            <span className="mt-2 px-3">
                                                <ToggleButtonField
                                                    id="req_doc"
                                                    name="req_doc"
                                                    // disabled={disableInput}
                                                    onChange={(event) => {
                                                        if(event.target.checked){
                                                            this.toggleReqVerifyModal();
                                                        }else{
                                                            // this.setState({isVerifiedToggle: false})
                                                            this.request_doc_action(event.target.checked)   
                                                        }
                                                    }}
                                                    defaultChecked={this.state.isVerifiedToggle}
                                                    value={this.state.isVerifiedToggle}
                                                    style={{marginTop: 0.5}}
                                                />
                                            </span>
                                        </div>
                                <div className="card">
                                    <div className="card-body" style={{ padding: "0.9rem" }}>
                                        {/* <p>According to the money laundry law in Australia and to protect our merchants and
                                            small business, you are required 'as per terms of use' to provide us with your
                                            100 points of identification including proof of address.
                                        </p>
                                        <p>Providing 100 points of identification (Once off).You must provide a total of 100 points
                                            of Australian or state-issued documents to prove your identity online.
                                        </p>
                                        <p>Different types of identity documents are worth different points.You can use different combinations
                                            of documents to make up your 100 points, but you must include atleast 1 primary document as part of your
                                            100 points.
                                        </p> */}
                                        <h6>Review documents and verify merchants identity below :</h6>
                                    </div>
                                    {this.renderAllTable()}
                                </div>
                                {/* <div style={{
                                    color: "#327ebc",
                                    marginLeft: "10px",
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    this.toggleRequestAdditionalDocModal(true)
                                }}>
                                    Send additional document request
                                </div> */}
                            </div>

                            <div className="col-sm-12 col-xl-12">

                                <div className="card-body" style={{ paddingBottom: "15px" }}>
                                    {/* <div className="ribbon ribbon-bookmark ribbon-primary">Additional Information</div> */}
                                    <div className="row">
                                    
                                        <div className="col-lg-12" style={{ marginTop: "15px" }}>
                                            <h5>By providing my digital signature, I do understand the following :</h5>
                                            {/* <p><input disabled={access_level == 1 ? true : false} required defaultChecked type="checkbox" />  Giving partial or misleading information could cause delays in my application to be processed and my funds
                                                to be released to my nominated bank account.
                                            </p> */}
                                            <p> <input disabled={access_level == 1 ? true : false} defaultChecked required type="checkbox" />  I am bound by the terms of use as outlined in terms & conditions & privacy policy of Linky and all of its associated APPs and websites.

                                            </p>
                                            <p> <input disabled={access_level == 1 ? true : false} defaultChecked required type="checkbox" />  I do solemnly declares all the information in this application is true and correct to the best of my knowledge and I am the authorised person to provide a digital signature for this application.
                                            </p>

                                        </div>

                                        <div className="card-footer col-lg-12" style={{ paddingLeft: "12px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                            <div className="pull-right">
                                                {(merchant_info.is_verified == 2 || merchant_info.is_verified == 0) || show_reject_button ?
                                                    <button onClick={() => this.onVerifyStatusClick("reject")} className="btn btn-danger btn-xs" style={{ margin: "0 15px 0 0" }}
                                                        type="button" disabled={access_level == 1 ? true : false}>Reject</button>
                                                     
                                                    :
                                                    null
                                                }
                                                {/* {( merchant_info.is_verified == 2) && 
                                                <button onClick={() => this.onVerifyStatusClick("reject")} className="btn btn-danger btn-xs" style={{ margin: "0 15px 0 0" }}
                                                type="button" disabled={access_level == 1 ? true : false}>Reject</button>} */}
                                                {(merchant_info.is_verified == 2 || merchant_info.is_verified == 3 || merchant_info.is_verified == 0) ?
                                                    <button onClick={() => this.onVerifyStatusClick("accept")} className="btn btn-secondary btn-xs"
                                                        style={{ margin: "0 15px 0 0" }} type="button" disabled={access_level == 1 ? true : false}>Verify</button>
                                                    :
                                                    null
                                                    // <span>Verifie</span>
                                                }
                                                {/* {( merchant_info.is_verified == 2) && 
                                                <button onClick={() => this.onVerifyStatusClick("accept")} className="btn btn-secondary btn-xs"
                                                style={{ margin: "0 15px 0 0" }} type="button" disabled={access_level == 1 ? true : false}>Verify</button>} */}
                                                {/* <button className="btn btn-primary btn-xs" style={{ margin: "0 15px 0 0" }} type="submit" disabled={access_level == 1 ? true : false}>Save</button> */}
                                            </div>
                                            <div className="pull-right">
                                                {merchant_info.is_verified == 1 && <p className="mt-2">Verification approved by {typeof merchant_info.is_verified_data == "undefined" ? "David M" : merchant_info.is_verified_data.verified_by_id.full_name}
                                                    <br /> {typeof merchant_info.is_verified_data == "undefined" ? null : `on ${this.render_date(merchant_info.is_verified_data.date)}`}</p>}
                                                {merchant_info.is_verified == 3 && <p className="mt-2">Verification rejected by {typeof merchant_info.is_verified_data == "undefined" ? "David M" : merchant_info.is_verified_data.verified_by_id.full_name}
                                                    <br /> {typeof merchant_info.is_verified_data == "undefined" ? null : `on ${this.render_date(merchant_info.is_verified_data.date)}`}</p>}
                                                {merchant_info.is_verified == 2 && <p className="mt-2">Documents verification under review</p>}
                                                {merchant_info.is_verified == 0 && <p className="mt-2">Documents not uploaded</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.isOpen && (
                                    <Lightbox
                                        mainSrc={this.state.imageSrc}
                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                    : null
                }
            </div>
        );
    }
}
export default VerifiedMerchantDocuments    